var stories = `1,% John 1,% In the Beginning was the Word,% OT,% Before Time
2,% Genesis 1,% The Creation,% OT,% Before 4000 BC
3,% Genesis 2,% The Garden of Eden,% OT,% Before 4000 BC
4,% Genesis 3,% The Fall of Man,% OT,% Before 4000 BC
5,% Genesis 4,% Cain kills Abel,% OT,% Before 3000 BC
6,% Genesis 5,% From Adam to Noah,% OT,% Before 3000 BC
7,% Genesis 6 – 10,% The Great Flood,% OT,% Before 2500 BC
8,% Job 1 – 42,% Job’s Suffering and Faith,% OT,% Before 2100 BC
9,% Genesis 11,% The Tower of Babel,% OT,% Before 2100 BC
10,% Genesis 12,% God Sends Abram to Egypt,% OT,% 2091 BC
11,% Genesis 13,% Abram and Lot Part Ways,% OT,% 2085 BC
12,% Genesis 14 – 50,% The Decendants of Abraham.,% OT,% 2084 – 1806 BC
13,% Exodus 1 – 3,% Israel in Egypt,% OT,% 1800 – 1446 BC
14,% Exodus 4 – 40,% The Ten Plagues on Egypt,% OT,% 1446 – 1445 BC
15,% Leviticus 1 – 23,% Laws for Sacrifices and Offerings,% OT,% 1445 BC
16,% Numbers 1 – 36,% Census,% Tribes,% Duties,% OT,% 1445 – 1407 BC
17,% Psalm 90,% Psalm of Moses,% OT,% 1407 BC
18,% Deuteronomy 1 – 34,% Moses’ Summary of Israel’s History,% OT,% 1406 BC
19,% Joshua 1 – 24,% Joshua Leads Israel Into Canaan.,% OT,% 1406 – 1375 BC
20,% Judges 1 – 21,% Israel’s relationship with the Gentiles in Canaan.,% OT,% 1375 – 1140 BC
21,% Ruth 1 – 4,% Naomi,% Ruth and Boaz,% OT,% 1140 BC
22,% 1 Samuel 1 – 20,% Samuel,% Saul,% and David,% OT,% 1100 – 1013 BC
23,% Psalm 59,% David’s Psalm of Deliverance,% OT,% 1013 BC
24,% 1 Samuel 21,% David at Nob and Gath,% OT,% 1012 BC
25,% Psalm 52,% David’s Psalm Fleeing Saul,% OT,% 1012 BC
26,% Psalm 34,% David’s Psalm Before Ahimelech,% OT,% 1012 BC
27,% Psalm 56,% David’s Psalm at Gath,% OT,% 1011 BC
28,% 1 Samuel 22,% Saul Slays the Priests of Nob,% OT,% 1011 BC
29,% Psalms 57,% David’s Psalms in the Cave,% OT,% 1011 BC
30,% Psalms 142,% David’s Psalms in the Cave,% OT,% 1011 BC
31,% 1 Samuel 23,% David Flees Saul,% OT,% 1011 BC
32,% Psalm 54,% David’s Psalm at Keilah,% OT,% 1011 BC
33,% 1 Samuel 24 – 31,% David vs. Saul,% OT,% 1011 BC
34,% 2 Samuel 1 – 4,% David’s Children,% OT,% 1010 – 1004 BC
35,% 1 Chronicles 1 – 10,% Genealogies of the Israelites,% OT,% 1003 BC
36,% 2 Samuel 5,% David Reigns over All Israel,% OT,% 1003 BC
37,% 1 Chronicles 11 – 15,% David Reigns over All Israel,% OT,% 1003 – 1000 BC
38,% 2 Samuel 6 – 8,% The Ark is Brought to Jerusalem,% OT,% 1000 BC
39,% Psalm 60,% David’s Psalm of Victory,% OT,% 998 BC
40,% Psalm 15,% David’s Psalm of Zion,% OT,% 998 BC
41,% Psalm 24,% David’s Psalm of Glory to God,% OT,% 998 BC
42,% 1 Chronicles 16,% David’s festival sacrifice,% OT,% 998 BC
43,% Psalms 96 and 105 and 106,% Psalms of Praise,% OT,% 998 BC
44,% 1 Chronicles 17 – 18,% David Purposes to build a Temple,% OT,% 997 BC
45,% 2 Samuel 9 – 10,% David and Mephibosheth,% OT,% 995 BC
46,% 1 Chronicles 19 – 20,% David Defeats Ammon and Aram,% OT,% 995 BC
47,% 2 Samuel 11 – 12,% David and Bathsheba,% OT,% 993 BC
48,% Psalm 51,% David’s Psalm of Repentance,% OT,% 991 BC
49,% 2 Samuel 13 – 14,% Amnon and Tamar,% OT,% 990 BC
50,% 1 Chronicles 21 – 27,% David Forces a Census,% prepares government,% OT,% 979 BC
51,% 2 Samuel 15 – 16,% Absalom’s Conspiracy,% OT,% 976 BC
52,% Psalm 63,% David’s Psalm of Thirst for God,% OT,% 972 BC
53,% 2 Samuel 17,% Hushai’s Warning Saves David,% OT,% 972 BC
54,% Psalms 41 and 55,% David Psalms of Deliverance,% OT,% 972 BC
55,% 2 Samuel 18 – 23,% Absalom Slain by Joab,% OT,% 972 BC
56,% Psalm 108,% David’s Psalm of Steadfastness,% OT,% 970 BC
57,% 2 Samuel 24,% David Counts the Fighting Men,% OT,% 970 BC
58,% 1 Chronicles 28 – 29,% David’s last days,% OT,% 970 BC
59,% 1 Kings 1 – 2,% David’s last days,% OT,% 970 BC
60,% Psalm 37,% David’s Psalm of Salvation,% OT,% 970 BC
61,% Psalm 72,% Psalm for Solomon,% OT,% 967 BC
62,% 2 Chronicles 1,% Solomon Asks for Wisdom,% OT,% 967 BC
63,% 1 Kings 3,% Solomon Asks for Wisdom,% OT,% 967 BC
64,% Psalm 45,% Psalm of Korah,% OT,% 967 BC
65,% 1 Kings 4 – 9,% Solomon’s Wisdom,% OT,% 967 BC
66,% 2 Chronicles 2 – 7,% Solomon Prepares for a Temple and Palace,% OT,% 966 BC
67,% Psalms 135 – 136,% Psalms of Solomon,% OT,% 959 BC
68,% 2 Chronicles 8,% Solomon’s buildings,% OT,% 959 BC
69,% Psalm 127,% Solomon Psalm of Blessing,% OT,% 950 BC
70,% Proverbs 1 – 31,% The Proverbs of Solomon,% OT,% 950 BC
71,% Songs 1 – 8,% Solomon’s Song of Songs,% OT,% 950 BC
72,% Psalms 1 – 150 (Assorted),% Psalms of Unknown Authors,% OT,% 979 BC
73,% 1 Kings 10,% The Queen of Sheba Visits Solomon,% OT,% 946 BC
74,% 2 Chronicles 9,% The Queen of Sheba Visits Solomon,% OT,% 946 BC
75,% 1 Kings 11,% Solomon’s Wives and Idolatry,% OT,% 939 BC
76,% Ecclesiastes 1 – 12,% Ecclesiastes Words of the Preacher,% OT,% 937 BC
77,% 1 Kings 12 – 13,% The Kingdom is Divided,% OT,% 931 BC
78,% 2 Chronicles 10 – 12,% Israelites Rebel against Rehoboam,% OT,% 930 BC
79,% 1 Kings 14 – 15,% Ahijah’s Prophecies against Jeroboam,% OT,% 925 BC
80,% 2 Chronicles 13 – 14,% Civil War against Jeroboam,% OT,% 913 BC
81,% 1 Kings 16,% Jehu’s prophecy against Baasha,% OT,% 909 BC
82,% 2 Chronicles 15 – 17,% Asa’s Reforms,% OT,% 895 BC
83,% 1 Kings 17 – 22,% Elijah Prays for Drought,% OT,% 863 BC
84,% Obadiah 1,% The Vision of Obadiah,% OT,% 853 BC
85,% 2 Chronicles 18 – 21,% Jehoshaphat Allies with Ahab,% OT,% 853 BC
86,% 2 Kings 1 – 11,% Elisha,% OT,% 852 BC
87,% 2 Chronicles 22 – 24,% Ahaziah Succeeds Jehoram in Judah,% OT,% 841 BC
88,% 2 Chronicles 23,% Jehoiada Makes Joash King,% OT,% 841 BC
89,% 2 Kings 12,% Joash Reigns Well,% OT,% 835 BC
90,% Joel 1 – 3,% The Word of the LORD to Joel,% OT,% 835 BC
91,% 2 Kings 13 – 14,% Jehoahaz’s wicked reign,% OT,% 812 BC
92,% 2 Chronicles 25,% Amaziah’s good reign,% OT,% 796 BC
93,% 2 Kings 15,% Azariah’s good reign,% OT,% 790 BC
94,% 2 Chronicles 26,% Uzziah Reigns in Judah,% OT,% 790 BC
95,% Amos 1 – 9,% The Words of Amos,% OT,% 766 BC
96,% Jonah 1 – 4,% Jonah Sent to Nineveh,% OT,% 760 BC
97,% Hosea 1 – 14,% Hosea’s Prophecies,% OT,% 753 BC
98,% 2 Chronicles 27 – 28,% Jotham Succeeds Uzziah,% OT,% 750 BC
99,% 2 Kings 16,% Wicked Reign of Ahaz,% OT,% 742 BC
100,% Isaiah 1 – 7,% Isaiah Complains of Zion’s Corruption,% OT,% 739 BC
101,% Micah 1 – 7,% The Word of the LORD to Micah,% OT,% 735 BC
102,% Isaiah 8 – 25,% Uriah and Zechariah,% OT,% 734 BC
103,% 2 Kings 17,% Hoshea the Last King of Israel,% OT,% 725 BC
104,% 2 Chronicles 29 – 31,% Hezekiah’s Good Reign,% OT,% 716 BC
105,% 2 Kings 20,% Hezekiah’s Illness and Healing,% OT,% 712 BC
106,% Isaiah 38 – 66,% Hezekiah Shows Treasures,% OT,% 711 BC
107,% 2 Kings 18,% Sennacherib Threatens Jerusalem,% OT,% 701 BC
108,% Isaiah 36,% Sennacherib Threatens Jerusalem,% OT,% 701 BC
109,% 2 Chronicles 32,% Sennacherib Threatens Jerusalem,% OT,% 701 BC
110,% Psalms 46 – 48,% Korah’s Psalms of Refuge (2Ch 32),% OT,% 701 BC
111,% 2 Kings 19,% Hezekiah’s Prayer,% OT,% 701 BC
112,% Isaiah 37,% Hezekiah’s Prayer,% OT,% 701 BC
113,% Nahum 1 – 3,% The Vision of Nahum,% OT,% 697 BC
114,% 2 Kings 21,% Manasseh’s Wicked Reign,% OT,% 687 BC
115,% 2 Chronicles 33,% Manasseh’s Wicked Reign,% OT,% 687 BC
116,% 2 Kings 22,% Josiah’s good reign,% OT,% 640 BC
117,% 2 Chronicles 34,% Josiah’s good reign,% OT,% 640 BC
118,% Zephaniah 1 – 3,% The Word of the LORD to Zephaniah,% OT,% 638 BC
119,% Jeremiah 1 – 10,% The Call of Jeremiah,% OT,% 627 BC
120,% Habakkuk 1 – 3,% The Oracle to Habakkuk,% OT,% 625 BC
121,% Jeremiah 11 – 12,% Jeremiah Proclaims God’s Covenant,% OT,% 622 BC
122,% 2 Kings 22 – 23,% Josiah Prepares for Temple Repair,% OT,% 621 BC
123,% 2 Chronicles 35 – 36,% Josiah Celebrates the Passover,% OT,% 621 BC
124,% Jeremiah 13 – 47,% Jeremiah Proclaims Covenant Is Broken,% OT,% 609 BC
125,% Daniel 1 – 2,% Daniel Refuses the King’s Portion,% OT,% 605 BC
126,% 2 Kings 24,% Rebellion of Jehoiakim,% OT,% 601 BC
127,% Ezekiel 1 – 19,% Ezekiel’s Prophecy at Chebar,% OT,% 593 BC
128,% Ezekiel 20 – 23,% Ezekiel Sees God Refuse the Elders,% OT,% 591 BC
129,% 2 Kings 25,% Siege of Jerusalem Begins,% OT,% 588 BC
130,% Jeremiah 48 – 49,% Jeremiah Prophesies against Moab,% OT,% 588 BC
131,% Ezekiel 24 – 25,% Siege of Jerusalem Begins,% OT,% 588 BC
132,% 2 Kings 25,% The Fall of Jerusalem,% OT,% 586 BC
133,% Psalms 74,% 79,% Jer. 52,% Psalms of Desolation (Jer. 52),% OT,% 586 BC
134,% Jeremiah 50,% 51,% Jeremiah Prophesies against Babylon,% OT,% 586 BC
135,% Lamentations 1 – 5,% Jeremiah’s Lamentations,% OT,% 586 BC
136,% Ezekiel 26 – 39,% Ezekiel Pronounces Judgment on Tyre,% OT,% 586 BC
137,% Daniel 3 – 4,% Shadrach,% Meshach,% and Abednego,% OT,% 585 BC
138,% Ezekiel 40 – 48,% Ezekiel’s Second Temple Vision,% OT,% 573 BC
139,% Daniel 5 – 12,% Daniel Interprets Handwriting on the Wall,% OT,% 539 BC
140,% Ezra 1 – 5,% The Proclamation of Cyrus,% OT,% 537 BC
141,% Haggai 1 – 2,% The Word of the LORD by Haggai,% OT,% 520 BC
142,% Zechariah 1 – 14,% The Word of the LORD to Zechariah,% OT,% 520 BC
143,% Ezra 6,% Temple Work Resumed by Darius’ Decree,% OT,% 520 BC
144,% Esther 1 – 10,% Queen Vashti Deposed,% OT,% 483 BC
145,% Ezra 7 – 10,% Ezra Journeys to Jerusalem,% OT,% 458 BC
146,% Nehemiah 1 – 13,% Nehemiah’s Prayer for the Exiles,% OT,% 445 BC
147,% Malachi 1 – 4,% The Word of the LORD by Malachi,% OT,% 430 BC
148,% Luke 1 – 2,% Birth of John the Baptist,% NT,% 1 BC
149,% Mathew 1,% Augustus Taxes the Roman Empire,% NT,% 1 BC
150,% Matthew 2,% Visit of the Magi,% NT,% 1 AD
151,% John 1,% Early Life of Jesus,% NT,% 1 AD
152,% Matthew 3,% John the Baptist Prepares the Way,% NT,% 30 AD
153,% Mark 1,% John the Baptist Prepares the Way,% NT,% 30 AD
154,% Luke 3,% John the Baptist Prepares the Way,% NT,% 30 AD
155,% Matthew 4,% Temptation of Jesus,% NT,% 30 AD
156,% Luke 4,% Temptation of Jesus,% NT,% 30 AD
157,% Luke 5,% Jesus Calls his First Disciples,% NT,% 30 AD
158,% John 2 – 4,% Wedding at Cana,% NT,% 30 AD
159,% Matthew 5 – 7,% Sermon on the Mount,% NT,% 30 AD
160,% Matthew 8,% Jesus Ministers in Galilee,% NT,% 31 AD
161,% Mark 2,% Jesus Ministers in Galilee,% NT,% 31 AD
162,% John 5,% The Pool of Bethesda,% NT,% 31 AD
163,% Matthew 12,% Jesus Lord of the Sabbath,% NT,% 31 AD
164,% Mark 3,% Jesus Lord of the Sabbath,% NT,% 31 AD
165,% Luke 6,% Jesus Lord of the Sabbath,% NT,% 31 AD
166,% Matthew 11,% Jesus Answers John’s Disciples,% NT,% 31 AD
167,% Luke 7,% Jesus Answers John’s Disciples,% NT,% 31 AD
168,% Matthew 13,% Jesus Speaks Many Parables,% NT,% 31 AD
169,% Mark 4,% Jesus Speaks Many Parables,% NT,% 31 AD
170,% Luke 8,% Jesus Speaks Many Parables,% NT,% 31 AD
171,% Mark 5,% Jesus Heals a Demoniac,% NT,% 31 AD
172,% Matthew 9,% Jesus Heals a Paralytic,% NT,% 31 AD
173,% Matthew 10,% Jesus Sends out His Twelve Apostles,% NT,% 32 AD
174,% Mark 6,% John the Baptist Beheaded,% NT,% 32 AD
175,% Matthew 14,% John the Baptist Beheaded,% NT,% 32 AD
176,% Luke 9,% Jesus Feeds the 5,000,% NT,% 32 AD
177,% John 6,% Jesus Feeds the 5,000,% NT,% 32 AD
178,% Matthew 15,% Teachings on Clean and Unclean,% NT,% 32 AD
179,% Mark 7,% Teachings on Clean and Unclean,% NT,% 32 AD
180,% Matthew 16,% Peter’s Confession of Christ,% NT,% 32 AD
181,% Mark 8,% Peter’s Confession of Christ,% NT,% 32 AD
182,% Matthew 17,% The Transfiguration,% NT,% 32 AD
183,% Mark 9,% The Transfiguration,% NT,% 32 AD
184,% Matthew 18,% Greatest and Least in the Kingdom,% NT,% 32 AD
185,% Luke 10 – 11,% Jesus Sends out the Seventy-two,% NT,% 32 AD
186,% John 7 – 10,% Jesus Teaches at the Feast of Tabernacles,% NT,% 32 AD
187,% Luke 12 – 17,% Jesus Speaks More Parables,% NT,% 33 AD
188,% John 11,% Jesus Raises Lazarus,% NT,% 33 AD
189,% Matthew 19 – 20,% Final Journey to Jerusalem,% NT,% 33 AD
190,% Mark 10,% Final Journey to Jerusalem,% NT,% 33 AD
191,% Luke 18,% Final Journey to Jerusalem,% NT,% 33 AD
192,% Matthew 21,% The Triumphal Entry,% NT,% 33 AD
193,% Mark 11,% The Triumphal Entry,% NT,% 33 AD
194,% Luke 19,% The Triumphal Entry,% NT,% 33 AD
195,% John 12,% The Triumphal Entry,% NT,% 33 AD
196,% Matthew 22 – 24,% Closing Ministry in Jerusalem,% NT,% 33 AD
197,% Matthew 25,% Closing Ministry in Jerusalem,% NT,% 33 AD
198,% Mark 12 – 13,% Closing Ministry in Jerusalem,% NT,% 33 AD
199,% Luke 20 – 21,% Thursday Before Passover,% NT,% 33 AD
200,% John 14 – 17,% Jesus Comforts His Disciples,% NT,% 33 AD
201,% Matthew 27,% Jesus’ Betrayal,% Trial,% Crucifixion,% NT,% 33 AD
202,% Mark 15,% Jesus’ Betrayal,% Trial,% Crucifixion,% NT,% 33 AD
203,% Luke 23,% Jesus’ Betrayal,% Trial,% Crucifixion,% NT,% 33 AD
204,% John 18 – 19,% Jesus’ Betrayal,% Trial,% Crucifixion,% NT,% 33 AD
205,% Matthew 28,% Jesus’ Resurrection,% NT,% 33 AD
206,% Mark 16,% Jesus’ Resurrection,% NT,% 33 AD
207,% Luke 24,% Jesus’ Resurrection,% NT,% 33 AD
208,% John 20 – 21,% Jesus’ Resurrection,% NT,% 33 AD
209,% Acts 1,% The Ascension,% NT,% 33 AD
210,% Acts 2 – 12,% The Holy Spirit Comes at Pentecost,% NT,% 33 AD
211,% James 1 – 5,% James Writes his Letter,% NT,% 45 AD
212,% Acts 13 – 18,% Paul’s First Missionary Journey,% NT,% 48 AD
213,% 1 Thess. 1 – 5,% Paul Writes to the Thessalonians,% NT,% 51 AD
214,% 2 Thess. 1 – 3,% Paul Writes again to the Thessalonians,% NT,% 52 AD
215,% Acts 19,% Paul in Ephesus,% NT,% 54 AD
216,% 1 Corinthians 1 – 16,% Paul Writes to the Corinthians,% NT,% 54 AD
217,% Galatians 1 – 6,% Paul Writes to the Galatians,% NT,% 54 AD
218,% Acts 20,% Paul in Macedonia and Greece,% NT,% 57 AD
219,% Romans 1 – 16,% Paul Writes to the Romans,% NT,% 57 AD
220,% 2 Corinthians 1 – 13,% Paul Writes again to the Corinthians,% NT,% 57 AD
221,% Acts 21 – 28,% Paul Returns to Jerusalem,% NT,% 59 AD
222,% Ephesians 1 – 6,% Paul Writes to the Ephesians,% NT,% 62 AD
223,% Philippians 1 – 4,% Paul Writes to the Philippians,% NT,% 62 AD
224,% Colossians 1 – 4,% Paul Writes to the Colossians,% NT,% 62 AD
225,% Philemon 1,% Paul Writes to Philemon,% NT,% 62 AD
226,% 1 Timothy 1 – 6,% Paul Writes to Timothy,% NT,% 63 AD
227,% 1 Peter 1 – 5,% Peter Writes his First Letter,% NT,% 64 AD
228,% Titus 1 – 3,% Paul Writes to Titus,% NT,% 66 AD
229,% 2 Timothy 1 – 4,% Paul Writes Again to Timothy,% NT,% 67 AD
230,% 2 Peter 1 – 3,% Peter Writes his Second Letter,% NT,% 67 AD
231,% Hebrews 1 – 13,% Letter to the Hebrews,% NT,% 68 AD
232,% Jude 1,% Jude Writes his Letter,% NT,% 68 AD
233,% 1 John 1 – 5,% John Writes his First Letter,% NT,% 90 AD
234,% 2 John 1,% John Writes his Second Letter,% NT,% 92 AD
235,% 3 John 1,% John Writes his Third Letter,% NT,% 94 AD
236,% Revelation 1 – 22,% John’s Revelation on Patmos,% NT,% 95 AD `

export class BibleStory {
  constructor(line) {
    this.properties = line.split(",% ")
  }

  countAttributes() {
    return this.properties.length
  }
  
  toString(){
    let str = "Story # ";
    for (let i = 0; i < this.properties.length; i++){
      str += this.properties[i];
      if (i !== this.properties.length -1){
        str += ", ";
      }
    }
    return str;    
  }

}

export function loadBibleStories() {
  let lines = stories.split('\n');
  let loadedStories = [];
  lines.forEach(element => {
    let bs = new BibleStory(element);
    loadedStories.push(bs);
  });
  return loadedStories;
}


