<template>

  <v-container>
    <head>
      <meta name="google-site-verification" content="y8ZZdxEj8FYpMWCuYScuEbq8_11P8PnbYrcmJiM-1Bo" />
    </head>
    <v-row class="text-center" id="rows">
      <v-col cols="12">
        <v-img
          :src="require('../assets/Cross.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 id="welcomeText" class="display-2 font-weight-bold mb-3"> Welcome to the Bible Randomizer Website! 
          <br> Press the 'SHUFFLE' button to randomly pick a story from the bible. 
          <br> You can choose to exclude stories from the New OR Old Testament, using the options below. 
        </h1>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col cols="12">
        <v-btn
          color="blue"
          class="white--text"
          rounded="true"
          large
          v-on:click="btnPress"
          id="randButton"
        >
          <v-icon> mdi-dice-5</v-icon>
          SHUFFLE
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col>
        <v-radio-group inline v-model="active1" @change="changed(1)">
          <template v-slot:label>
            <div><strong>Include Old Testament Stories?</strong></div>
          </template>
          <v-radio label="Yes" :value="1" name="active1"></v-radio>
          <v-radio label="No" :value="2" name="active1"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col>
        <v-radio-group inline v-model="active2" @change="changed(2)">
          <template v-slot:label>
            <div><strong>Include New Testament Stories?</strong></div>
          </template>
          <v-radio name="active2" label="Yes" :value="1"></v-radio>
          <v-radio name="active2" label="No" :value="2"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col>
        <v-btn
          color="blue"
          large
          rounded="true"
          class="white--text"
          v-on:click="resetSelections"
          >Reset Filters
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",
  mounted() {
    this.load();
    window.alert(`This site does not yet have support for mobile phone devices. 
    For a better viewing experience, in your mobile browser's settings, set the "Desktop Site" setting to On - 
    or have the checkbox checked if it is a checkbox.`);
    this.loaded = true;
  },

  methods: {
    load() {
      this.bibleStories = this.$loadBibleStories();
      this.setBackupList();
      let ele = document.getElementById(this.storyID);
      this.btnShuffleText = this.$shuffleTextFunctions.newShuffleText(
        ele,
        this.shuffleDur
      );
      let str = `Get Started
1. Product Download
Login to the Website and download the product <https://mandrillapp.com/track/click/31115364/account.appeon.com?p=eyJzIjoiNnU2dlhFU2ZFel9TampUVGRmRzZxbUVNTVNRIiwidiI6MSwicCI6IntcInVcIjozMTExNTM2NCxcInZcIjoxLFwidXJsXCI6XCJodHRwczpcXFwvXFxcL2FjY291bnQuYXBwZW9uLmNvbVxcXC9kb3dubG9hZFwiLFwiaWRcIjpcImI4YTM0ZTUwYTc0YTQ5MjA4N2IxZWVhZGRjMjBhNjI3XCIsXCJ1cmxfaWRzXCI6W1wiMGQwZGQ3MDY0MjEzNmRjNTMzOWQ2Y2JlNWJlYjdhM2Q5NTA5MjBlN1wiXX0ifQ>  or contact your authorized reseller to obtain the appropriate installation files.
2. Product Activation
Follow the instructions <https://mandrillapp.com/track/click/31115364/docs.appeon.com?p=eyJzIjoiZkU4YlRBWU5iQ1NzQVNmR3ltc280eTVySHQ4IiwidiI6MSwicCI6IntcInVcIjozMTExNTM2NCxcInZcIjoxLFwidXJsXCI6XCJodHRwczpcXFwvXFxcL2RvY3MuYXBwZW9uLmNvbVxcXC9wYjIwMjJcXFwvYXBwZW9uX2xpY2Vuc2VfdXNlcl9ndWlkZVxcXC9pbmRleC5odG1sXCIsXCJpZFwiOlwiYjhhMzRlNTBhNzRhNDkyMDg3YjFlZWFkZGMyMGE2MjdcIixcInVybF9pZHNcIjpbXCI1ZWFjMDliOTVkMGM3ZmEzY2YzMGMxOTM2N2I5YmJjMDI2MzRmODQyXCJdfSJ9>  to finish the product activation.
`;
      this.setElementText(str);
    },

    changed(choice) {
      this.resetBibleStories();
      if (choice === 1) {
        this.splitList("OT");
      } else if (choice === 2) {
        this.splitList("NT");
      }
    },

    resetSelections() {
      this.active1 = 1;
      this.active2 = 1;
      this.resetBibleStories();
    },

    bothOptionsSetToNo() {
      return this.active1 === 2 && this.active2 === 2;
    },

    setBackupList() {
      this.backupList = [...this.bibleStories];
    },

    resetBibleStories() {
      this.bibleStories = [...this.backupList];
    },

    splitList(test) {
      if (test === "OT") {
        if (this.bothOptionsSetToNo()) {
          this.active2 = 1;
          this.bibleStories = this.bibleStories.slice(147);
        }
        // if the active1 (OT) is set to No, remove the first 147 stories
        else if (this.active1 === 2) {
          this.bibleStories = this.bibleStories.slice(147);
        }
      } else if (test === "NT") {
        if (this.bothOptionsSetToNo()) {
          this.active1 = 1;
          this.bibleStories = this.bibleStories.slice(0, 147);
        }
        // if the active2 (NT) is set to No, remove all the stories after the 147th
        else if (this.active2 === 2) {
          this.bibleStories = this.bibleStories.slice(0, 147);
        }
      }
    },

    btnPress() {
      if (!this.loaded) {
        this.load();
        this.loaded = true;
      }
      if (this.btnShuffleText) {
        if (this.btnShuffleText.isRunning) {
          return;
        } else {
          this.selectedStoryIndex = Math.floor(
            Math.random() * (this.bibleStories.length + 1)
          );
          let txt = this.bibleStories[this.selectedStoryIndex].toString();
          this.btnShuffleText.start();
          this.setElementText(txt);
        }
      }
    },

    setElementText(str) {
      this.btnShuffleText.setText(str);
    },
  },

  data: () => ({
    backupList: [],
    bibleStories: [],
    btnShuffleText: {},
    loaded: false,
    lastOTIndex: 147,
    firstNTIndex: 148,
    selectedStoryIndex: -1,
    storyID: "welcomeText",
    shuffleDur: 2000,
    active1: 1,
    active2: 1,
  }),
};
</script>