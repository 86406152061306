import ShuffleText from 'shuffle-text';

var textElement = {};

function newShuffleText(element, duration){
    textElement = element;
    let st = new ShuffleText(element);
    st.duration = duration;
    return st;
}

function setElementText(text){
    textElement.innerHTML = text;
    console.log("printing out innerHTML" + JSON.stringify(textElement.innerHTML));
}

export var functions = { newShuffleText, setElementText }