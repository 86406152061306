<template>
  <v-app>
    <v-app-bar app color="primary" dark class="text-center">
      <h1>Bible Story Randomizer Website</h1>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <HelloWorld />
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from "./components/HelloWorld";

export default {
  name: "App",

  components: {
    HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>
#app {
  background-image: url("./assets/LionAndLamb.jpeg");
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(241, 240, 240, 0.6);
}
</style>